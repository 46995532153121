<template>
  <main class="landing">
    <div
      class="landing__hero"
      :style="{ backgroundImage: `url(${$baseUrl}images/home-bg-desktop.svg)` }"
      v-if="$tvaMq === 'desktop'"
    >
      <img
        :src="`${$baseUrl}images/home-logo.svg`"
        alt=""
        class="landing__hero__title"
      />

      <LandingHeroBanner class="landing__hero__banner" />

      <button class="landing__hero__cta" @click="scrollDown">
        <svgicon
          name="down-arrow"
          height="28px"
          color="var(--basic-white)"
        ></svgicon>
        <span>Scorri verso il basso</span>
      </button>
    </div>
    <ConstitutionHeader class="landing__header" />
    <section class="landing__intro">
      <div class="landing__intro__section">
        <img
          :src="`${$baseUrl}images/landing-logo.svg`"
          :alt="content.title"
          class="landing__intro__logo"
        />

        <div class="landing__intro__text-block block-1">
          <p
            v-for="(paragraph, i) of content.landingTexts1"
            :key="'par-1_' + i"
            class="landing__intro__text-block__paragraph"
            v-html="paragraph"
          />
        </div>
      </div>
      <div class="landing__intro__section landing__intro__section-second">
        <p
          class="landing__intro__question"
          v-html="content.landingQuestion"
          v-if="$tvaMq !== 'desktop'"
        />

        <div
          class="landing__intro__text-block block-2"
          v-if="$tvaMq !== 'desktop'"
        >
          <p
            v-for="(paragraph, i) of content.landingTexts2"
            :key="'par-1_' + i"
            v-html="paragraph"
          />
        </div>
      </div>

      <ScrollIndicator
        class="landing__intro__scroll-indicator"
        v-if="$tvaMq !== 'desktop'"
      />
    </section>

    <section class="landing__intro second" v-if="$tvaMq === 'desktop'">
      <img
        :src="`${$baseUrl}images/family.svg`"
        class="landing__intro__family"
        alt=""
      />
      <div>
        <p class="landing__intro__question" v-html="content.landingQuestion" />

        <div class="landing__intro__text-block block-2">
          <p
            v-for="(paragraph, i) of content.landingTexts2"
            :key="'par-1_' + i"
            class="landing__intro__text-block__paragraph"
            v-html="paragraph"
          />
          <ConstitutionCard
            v-for="link of content.links"
            :key="link.pathName"
            class="landing__kpi__links__link"
            @onCardClick="navigateTo(link.pathName)"
          >
            <div class="landing__kpi__links__link__title">
              {{ link.title }}
            </div>
            <div
              class="landing__kpi__links__link__subtitle"
              v-html="link.subTitle"
            />
          </ConstitutionCard>
        </div>
      </div>
    </section>

    <section class="landing__kpi">
      <img
        :src="`${$baseUrl}images/landing-kpi-1.svg`"
        alt=""
        class="landing__kpi__img img-1"
        v-if="$tvaMq !== 'desktop'"
      />

      <div class="landing__kpi__wrapper" v-else>
        <LineBox
          v-for="(line, index) in content.kpisLines"
          :key="line.text"
          :value="line.value"
          :postfix="line.postfix"
          :text="line.text"
          :color="line.color"
          :even="index % 2 != 0"
        />
      </div>

      <img
        :src="`${$baseUrl}images/landing-kpi-2.svg`"
        alt=""
        class="landing__kpi__img img-2"
      />

      <p class="landing__kpi__date" v-html="content.dateSign" />

      <img
        :src="`${$baseUrl}images/landing-illustration.webp`"
        alt=""
        class="landing__kpi__img img-3"
        v-if="$tvaMq === 'desktop'"
      />

      <img
        :src="`${$baseUrl}images/landing-illustration.png`"
        alt=""
        class="landing__kpi__img img-3"
        v-else
      />

      <div class="landing__kpi__links">
        <ConstitutionCard
          v-for="link of content.links"
          :key="link.pathName"
          class="landing__kpi__links__link"
          @onCardClick="navigateTo(link.pathName)"
        >
          <div class="landing__kpi__links__link__title">
            {{ link.title }}
          </div>
          <div
            class="landing__kpi__links__link__subtitle"
            v-html="link.subTitle"
          />
        </ConstitutionCard>
      </div>
    </section>
    <Transition name="fade">
      <ModalSocialShare v-if="getModalState"
    /></Transition>
  </main>
</template>

<script>
import ConstitutionCard from "../components/ConstitutionCard.vue";
import ConstitutionHeader from "../components/ConstitutionHeader.vue";
import LineBox from "../components/LineBox.vue";
import ModalSocialShare from "../components/ModalSocialShare.vue";
import ScrollIndicator from "../components/ScrollIndicator.vue";
import LandingHeroBanner from "@/components/LandingHeroBanner.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ConstitutionCard,
    ConstitutionHeader,
    ScrollIndicator,
    LineBox,
    ModalSocialShare,
    LandingHeroBanner,
  },
  computed: {
    content() {
      return this.$t("landing");
    },
    ...mapGetters(["getModalState"]),
  },
  methods: {
    navigateTo(pageName) {
      this.$router.push({ name: pageName });
    },
    scrollDown() {
      window.scrollTo({ left: 0, top: window.innerHeight, behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
.landing {
  overflow-x: hidden;
  &__hero {
    position: relative;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100 + toRem(20));
    background-color: $primary-dark;
    background-size: cover;

    &__title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: toVW(338);
    }

    &__cta {
      @include button-xs-italic;
      position: absolute;
      bottom: toRem(43);
      left: 50%;
      transform: translate(-50%, 0);
      color: var(--basic-white);
      display: flex;
      align-items: center;

      .svg-icon {
        transition: transform 0.3s ease;
      }

      &:hover {
        .svg-icon {
          transform: translateY(5px);
        }
      }

      & > span {
        margin-left: toRem(16);
      }
    }

    &__banner {
      position: absolute;
      bottom: toRem(50);
      left: 0;
    }
  }

  &__header {
    position: fixed;
    z-index: 2;
  }

  &__intro {
    padding: toVW(40) toVW(221);
    position: relative;

    &__section {
      display: flex;
      align-items: flex-start;
      &-second {
        flex-direction: column;
        margin: 10rem 0;
      }
    }

    &__logo {
      padding-top: $page-header;
      width: toVW(426);
    }

    &__text-block {
      @include theme-paragraph;
      position: relative;

      &__paragraph {
        margin-bottom: 3rem;
      }

      &.block-1 {
        width: toVW(713);
        right: 0;
        margin-left: 5rem;
        margin-top: toVW(207);
      }

      &.block-2 {
        left: toVW(150);
        margin-top: 3rem;
        width: calc(100% - toVW(180));
      }
    }

    &__question {
      font-family: "GT Super";
      font-weight: 700;
      font-size: 5rem;
      line-height: 105%;
 
      bottom: 19vh;
      ::v-deep em {
        font-style: normal !important;
      }
    }

    &__scroll-indicator {
      position: absolute;
      right: toVW(167);
      bottom: toVW(158);
    }
  }

  &__kpi {
    background-color: $primary-light;
    padding-top: toVW(100);
    border-radius: toVW(20) toVW(20) 0 0;

    &__links {
      --card-color: var(--basic-white);

      border-radius: toVW(20) toVW(20) 0 0;
      padding: toVW(100) toVW(93);
      display: flex;
      background-color: $primary-light;
      position: fixed;
      width: 100%;
      bottom: 0;

      &__link {
        width: 50%;
        text-align: center;

        &:first-child {
          margin-right: toVW(31);
        }

        &__title,
        &__subtitle {
          padding-left: 5rem;
        }

        &__title {
          @include title-h-3;

          font-weight: normal;
          text-transform: uppercase;
          margin-bottom: toVW(20);
        }

        &__subtitle {
          @include button-xl;
          font-family: "GT Super";
          letter-spacing: 0.015em;
          ::v-deep em {
            font-style: normal;
          }
        }
      }
    }

    &__date {
      font-family: "Inter";
      font-size: toVW(34);
      margin: toVW(91) 0;

      text-align: center;
      color: var(--basic-dark-grey);
    }

    &__img {
      width: 100%;

      &.img-1 {
        padding: 0 toVW(221);
        padding-bottom: toVW(343);
      }

      &.img-2 {
        padding: 0 toVW(83);
        padding-bottom: toVW(51);
      }

      &.img-3 {
        padding-bottom: toVW(200);
      }
    }
  }

  --desktop-max-width: 1552px;
  .desktop > & {
    .landing__intro {
      height: unset;
      padding: toRem(132) toRem(185);
      border-radius: toRem(20);
      max-width: var(--desktop-max-width);
      margin: 0 auto;

      &:not(.second) {
        background-color: var(--basic-white);
        transform: translateY(toRem(-20));
      }

      &__section-second {
        display: none;
      }

      &.second {
        padding: toRem(39) toRem(185);
        padding-left: toRem(125);
        padding-bottom: toRem(75);
        gap: toRem(75);
        display: flex;

        .landing__intro__question {
          position: unset;
          font-size: 3.44rem !important;
          width: 100%;
          margin-bottom: toRem(20);
          letter-spacing: 0.01em;
        }

        .landing__kpi__links__link {
          width: 100%;
          @include title-h-3;
          justify-content: unset;
          filter: none;
          border: none;
          @include shadow-1;
          background-color: var(--basic-white);

          height: fit-content;

          &:first-of-type {
            margin-bottom: toRem(30);
          }

          &__title {
            padding: 0;
            font-size: 1rem;
            margin-bottom: toRem(10);
          }

          &__subtitle {
            padding: 0;
            font-family: "GT Super";
            font-size: toRem(30);
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 30px */
            letter-spacing: 0.015em;
            white-space: nowrap;
            ::v-deep em {
              font-style: normal;
            }
          }
        }
      }

      &__family {
        width: toRem(441);
      }

      &__logo {
        position: absolute;
        width: toRem(300);
        right: toRem(185);
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
        
      }

      &__text-block {
        position: unset;
        font-size: 1.375rem;
        width: 50%;

        &__paragraph {
          margin-bottom: 1.5rem;
        }

        &.block-2 {
          width: 100%;
        }
      }
    }
    .landing__header {
      top: 0;
    }
    .landing__kpi {
      &__wrapper {
        display: flex;
        flex-direction: column;
        padding: 0 toVW(91);
        padding-bottom: toVW(115);

        max-width: var(--desktop-max-width);
        margin: 0 auto;

        & > *:nth-child(even) {
          align-self: flex-end;
        }
      }

      &__links {
        position: unset;
        background-color: $basic-white;
        height: toVW(200);
        box-shadow: 0px 0px 10px 0px rgba(135, 135, 135, 0.5);
        padding: toVW(50);

        &__link {
          text-align: start;

          width: 100%;
          @include title-h-3;
          justify-content: unset;
          filter: none;
          border: none;
          @include shadow-1;

          &:first-child {
            margin-right: toVW(35);
          }

          &__title {
            padding: 0;
            font-size: 1rem;
            margin-bottom: toRem(10);
          }

          &__subtitle {
            padding: 0;
            font-family: "GT Super";
            font-size: toRem(30);
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 30px */
            letter-spacing: 0.015em;
            white-space: nowrap;
            ::v-deep em {
              font-style: normal;
            }
          }
        }
      }

      &__date {
        font-family: "Inter";
        font-size: toVW(16);
        margin: toVW(126) 0 0 0;

        text-align: center;
        color: var(--basic-black);

        z-index: 1;
        position: relative;
      }

      &__img {
        width: 100%;
        max-width: var(--desktop-max-width);
        margin: 0 auto;
        display: block;

        &.img-1 {
          padding: 0 toVW(91);
          padding-bottom: toVW(115);
        }

        &.img-2 {
          padding: 0 toVW(83);
          padding-bottom: toVW(51);
        }

        &.img-3 {
          margin-top: -14rem;
          padding-bottom: 0;
          max-width: none;
          z-index: 0;
          display: block;
          position: relative;
        }
      }
    }
    @media (max-width: 1160px) {
      .landing__intro {
        &__logo {
          width: 20vw;
        }
        &__family {
          width: 20vw;
        }
      }
    }
  }
}
</style>
