<template>
  <div :class="['line-box', { even: even }, { animate: isVisible }]" ref="line">
    <p class="line-box__value" >
      {{ value }}
    </p>
    <span v-if="postfix" class="line-box__post">{{ postfix }}</span>
    <p v-html="text" class="line-box__text" />
    <div
      :class="['line-box__line']"
      :style="{ backgroundColor: color }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "LineBox",
  props: ["value", "text", "postfix", "color", "even"],
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    handleIntersection(entries) {
      if (entries[0].isIntersecting) {
        this.isVisible = true;
      }
    },
  },
  mounted() {
    const observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      threshold: 1,
    });
    observer.observe(this.$refs.line);
  },
};
</script>

<style lang="scss" scoped>
.line-box {
  width: fit-content;
  color: var(--basic-black);
  display: flex;
  align-items: center;
  position: relative;

  &.even {
    flex-direction: row-reverse;
    .line-box__text {
      margin-left: toRem(0);
    }
  }

  &__value {
    font-family: Spectral;
    font-size: toRem(200);
    font-style: italic;
    font-weight: 800;
    line-height: 105%; /* 210px */
    letter-spacing: toRem(-15);
    z-index: 1;

    transform: translateY(-15px);
    transition: transform 1.2s ease .2s;

    position: relative;
  }

  &__post {
    font-size: toRem(40);
    font-style: normal;
    font-weight: 700;
    position: absolute;
    bottom: -2rem;
    left: 1.6rem;
  }

  &__text {
    margin-left: toRem(36);
    font-family: Inter;
    font-size: toRem(24);
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }

  &__line {
    position: absolute;
    bottom: toRem(35);
    left: 0;
    width: 0;
    height: toRem(20);
    border-radius: toRem(20);
    transition: width 1.8s ease .1s;
  }
    &.animate {
      .line-box {
        &__line {
          width: 100%;
        }
        &__value {
          transform: translateY(0px);
        }
      }
 
    }
  
}
</style>
