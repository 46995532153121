import { render, staticRenderFns } from "./LandingHeroBanner.vue?vue&type=template&id=5fc4e564&scoped=true&"
var script = {}
import style0 from "./LandingHeroBanner.vue?vue&type=style&index=0&id=5fc4e564&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fc4e564",
  null
  
)

export default component.exports