<template>
  <div class="scroll-indicator">
    {{ $t("general.ctas.scroll") }}

    <svgicon
      class="scroll-indicator__icon"
      name="small-arrow"
      height="1rem"
      color="var(--primary-dark)"
    />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.scroll-indicator {
  @include button-s-italic;
  @include flip-text;

  position: relative;
  color: $primary-dark;
  height: toRem(357);
  border-left: 1px solid $primary-dark;
  padding-left: 0.5rem;
  text-align: right;

  &__icon {
    position: absolute;
    top: 0;
    left: -0.05rem;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
}
</style>
